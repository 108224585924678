import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import chathub from "../../Assets/Projects/chathub.png";
import challenge from "../../Assets/Projects/challengeclash.png";
import harmonyhub from "../../Assets/Projects/harmonyhub.png";
import uplift from "../../Assets/Projects/uplift.png";
import dietplanner from "../../Assets/Projects/dietplanner.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chathub}
              isBlog={false}
              title="ChatHub"
              description="Personalized and friendly language learning hub! Jump into your chat room, mix lessons with real talk. Enjoy instant messaging, image sharing, and reactions. Powered by Django, ChatHub makes learning fun and interactive—a smooth ride to language skills!"
              //ghLink="https://github.com/soumyajit4419/Chatify"
              //demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={challenge}
              isBlog={false}
              title="Challenge-Clash"
              description="Explore Challenge-Clash, a dynamic hub powered by Django! Experience head-to-head showdowns, vibrant videos, and epic descriptions in time-limited challenges. Winners are determined by community votes, making it a lively arena of friendly competition and excitement!"
              //ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              //demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={harmonyhub}
              isBlog={false}
              title="Harmony-Hub"
              description="Love and tech unite! Our ChatGPT-powered chat app to strengthen relationships. When conflicts arise, our unique third-person bot steps in, asking insightful questions to foster communication and understanding. Watch as HarmonyHub transforms conflicts into resolutions, bringing couples closer together through the art of conversation."
              //ghLink="https://github.com/soumyajit4419/Editor.io"
              //demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={uplift}
              isBlog={false}
              title="Uplift"
              description="Get customized workout plans and diet plans by providing your fitness data. Our smart Ai algorithm integrated with ChatGpt analyzes your information to create personalized workout routines and diet plans designed to help you achieve your fitness goals effectively."
              //ghLink="https://github.com/soumyajit4419/Plant_AI"
              //demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dietplanner}
              isBlog={false}
              title="AI-Recipe-Maker"
              description="Unlock personalized nutrition with AI-Powered Diets. Tailored meal plans for your unique profile and goals. Introducing our Recipe Assistant powered by Django and React, simply enter your ingredients, and our AI will work its magic, creating delicious recipes tailored to what you have. Embrace a healthier, more convenient you!"
              //ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

  
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
